/** @format */

export enum EUserType {
	teacher = 'Teacher',
	parent = 'Parent',
	lsa = 'LSA'
}

export enum ESourceType {
	classroomBook = 'ClassroomBook',
	classroomBooklet = 'ClassroomBooklet',
	websiteText = 'WebsiteText',
}

export enum ELessonActivity {
	coloringGame = "coloring_game",
	labelingGame = "labeling_game",
	sequenceGame = "sequence_game",
	fillInTheBlank = "fill_in_game",
	matchingGame = "matching_game",
	shortAnswerQuestions = "short_answer",
	tableCompletion = "table_completion",
	trueFalse = "true_false",
	multipleChoice = "multiple_choice",
	
	shortVideo = 'short_video',
}