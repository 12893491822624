/** @format */

import React, {FC, memo, useCallback} from 'react';

import {TLearningMaterials, TParagraph} from '../learningMaterialsTypes';
import Paragraph from "./Paragraph";


interface MaterialsParagraphsProps {
	title?: string;
	subTitle?: string;
	
	editable: boolean;
	
	paragraphs: TLearningMaterials['paragraphs'];
	setParagraphs: React.Dispatch<React.SetStateAction<TLearningMaterials['paragraphs']>>;
}

const LearningMaterialsParagraphs: FC<MaterialsParagraphsProps> = props => {
	const {
		title,
		subTitle,
		
		editable,
		
		paragraphs,
		setParagraphs,
	} = props;
	
	const handleChangeParagraph = useCallback((key: string, action: React.SetStateAction<TParagraph>) => {
		setParagraphs(paragraphs => ({
			...paragraphs,
			[key]: typeof action === 'function' ? action(paragraphs[key]) : action,
		}));
	}, [setParagraphs]);
	
	let hasTitle = false;
	const getTitle = () => {
		if (hasTitle) {
			return undefined;
		} else {
			hasTitle = true;
			return title;
		}
	}
	
	let hasSubtitle = false;
	const getSubTitle = () => {
		if (hasSubtitle) {
			return undefined;
		} else {
			hasSubtitle = true;
			return subTitle;
		}
	}
	
	return (
		<React.Fragment>
			{Object.entries(paragraphs).map(([key, paragraph]) => (
				<Paragraph
					key={key}
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					paragraph={paragraph}
					setParagraph={paragraph => handleChangeParagraph(key, paragraph)}
				/>
			))}
		</React.Fragment>
	)
}

export default memo(LearningMaterialsParagraphs);