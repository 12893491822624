/** @format */

import React, {memo, useCallback, useState} from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import {
	headingsPlugin,
	MDXEditor,
	tablePlugin,
	markdownShortcutPlugin,
	quotePlugin,
	toolbarPlugin,
	listsPlugin,
	linkPlugin,
	linkDialogPlugin,
	imagePlugin,
	codeBlockPlugin,
	thematicBreakPlugin,
	frontmatterPlugin,
	UndoRedo,
	BoldItalicUnderlineToggles,
} from '@mdxeditor/editor';

import Checkbox from '../../../components/Checkbox/Checkbox';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import TextArea from './TextArea';

import {TTextMaterialStructure, TWordMaterialStructure} from '../learningMaterialsTypes';

import classes from './editingBlock.module.scss';

import editIconImg from './editIcon.svg';
import {useTranslation} from "react-i18next";

interface TextEditingBlockProps<MaterialStructure> {
	title?: string;
	subTitle?: string;
	
	editable: boolean;
	
	text: MaterialStructure;
	setText: React.Dispatch<React.SetStateAction<MaterialStructure>>;
}

const mdxPlugins = [
	toolbarPlugin({
		toolbarClassName: classes.editing_toolbar_pane,
		toolbarContents: () => (
			<React.Fragment>
				<div className={classes.editing_toolbar_side}>
					<BoldItalicUnderlineToggles/>
				</div>
				<div className={classes.editing_toolbar_side}>
					<UndoRedo/>
				</div>
			</React.Fragment>
		)
	}),
	listsPlugin(),
	quotePlugin(),
	headingsPlugin(),
	linkPlugin(),
	linkDialogPlugin(),
	imagePlugin(),
	tablePlugin(),
	thematicBreakPlugin(),
	frontmatterPlugin(),
	codeBlockPlugin({defaultCodeBlockLanguage: 'txt'}),
	markdownShortcutPlugin()
]

const TextEditingBlock = <MaterialStructure extends (TTextMaterialStructure | TWordMaterialStructure)>(props: TextEditingBlockProps<MaterialStructure>) => {
	const {
		title,
		subTitle,
		
		editable,
		
		text,
		setText,
	} = props;
	
	const {t} = useTranslation();
	
	const [isEditing, setIsEditing] = useState(false);
	const [editingContent, setEditingContent] = useState('');
	const [editingWord, setEditingWord] = useState('');
	
	const handleChangeComment = useCallback((comment: string) => {
		setText(text => ({
			...text,
			comment,
		}))
	}, [setText]);
	
	const handleChangeRegenerate = useCallback((regenerate: boolean) => {
		setText(text => ({
			...text,
			regenerate,
		}))
	}, [setText]);
	
	const header = (title !== undefined || subTitle !== undefined) && (
		<div className={classes.block_header}>
			{title && (<h4>{title}</h4>)}
			{subTitle && (<h5>{subTitle}</h5>)}
		</div>
	)
	
	const handleEnterEdit = useCallback(() => {
		setEditingContent(getIsWord(text) ? text.description : text.text);
		setIsEditing(true);
		if (getIsWord(text)) {
			setEditingWord(text.word);
		}
	}, [text]);
	
	const handleSaveEdit = useCallback(() => {
		setText(text => {
			if (getIsWord(text)) {
				return {
					...text,
					update: true,
					word: editingWord,
					description: editingContent,
					[originalTextKey]: text[originalTextKey] ?? text.description,
				}
			} else {
				return {
					...text,
					update: true,
					text: editingContent,
					[originalTextKey]: text[originalTextKey] ?? text.text,
				}
			}
		});
		setIsEditing(false);
	}, [editingContent, editingWord]);
	
	const handleExitEdit = useCallback(() => {
		setIsEditing(false);
	}, [setIsEditing])
	
	if (isEditing) {
		return (
			<div className={classes.editing_container}>
				{header}
				{getIsWord(text) && (
					<Input value={text.word} onSet={setEditingWord}/>
				)}
				<MDXEditor
					className={classes.editing_editor}
					contentEditableClassName={classes.editing_editor_content}
					// suppressHtmlProcessing
					onChange={setEditingContent}
					plugins={[]}
					
					markdown={editingContent}
				/>
				<div className={classes.editing_actions}>
					<Button variant="primary" onClick={handleSaveEdit} disabled={editingContent === (getIsWord(text) ? text.description : text.comment)}>{t('common.save')}</Button>
					<Button variant="outline" onClick={handleExitEdit}>{t('common.back')}</Button>
				</div>
			</div>
		)
	} else {
		return (
			<div className={classes.block}>
				{header}
				<div className={classes.block_separator}>
					<Markdown remarkPlugins={[remarkGfm]} className={classes.block_content_text}>
						{getIsWord(text) ? `**${text.word}** - ${text.description}` : text.text}
					</Markdown>
					<div className={classes.block_actions}>
						{(editable && !text.regenerate) && (
							<div className={classes.action_container}>
								<label className={classes.label}>
									<button
										className={classes.block_edit_button}
										onClick={handleEnterEdit}
									>
										<img
											src={editIconImg}
											width={20}
											height={20}
											alt="edit"
										/>
									</button>
									<span>{t('common.edit')}</span>
								</label>
							</div>
						)}
						{!text.update && (
							<div className={classes.action_container}>
								<label className={classes.label}>
									<Checkbox
										checked={text.regenerate}
										onSet={handleChangeRegenerate}
									/>
									<span>{t('common.regenerate')}</span>
								</label>
							</div>
						)}
						{text.regenerate && (
							<TextArea
								value={text.comment}
								onSet={handleChangeComment}
								autoFocus
							/>
						)}
					</div>
				</div>
			</div>
		)
	}
}

const getIsWord = (text: TTextMaterialStructure | TWordMaterialStructure): text is TWordMaterialStructure => {
	return (
		'word' in text &&
		'description' in text
	)
}

const originalTextKey = Symbol('original_text')

export default memo(TextEditingBlock);