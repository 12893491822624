/** @format */
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

export const useIsMobileView = () => {
	const [isMobileView, setIsMobileView] = useState(
		window.matchMedia("(pointer: coarse)").matches &&
		window.matchMedia("(max-width: 500px)").matches
	);
	
	useEffect(() => {
		const pointerMql = window.matchMedia("(pointer: coarse)");
		const windowWidthMql = window.matchMedia("(max-width: 500px)");
		
		const handler = () => {
			setIsMobileView(
				pointerMql.matches &&
				windowWidthMql.matches
			);
		}
		
		pointerMql.addEventListener('change', handler);
		windowWidthMql.addEventListener('change', handler);
		
		return () => {
			pointerMql.removeEventListener('change', handler);
			windowWidthMql.removeEventListener('change', handler);
		}
	}, [])
	
	return isMobileView;
};

export const useLanguageOptions = () => {
	const {i18n, t} = useTranslation();
	
	return useMemo(() => [
		{value: 'en', label: t('common.languages.english')},
		{value: 'he', label: t('common.languages.hebrew')},
	], [i18n.language]);
}