/** @format */

import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import LanguageSelect from './LanguageSelect';

import {useLanguageOptions} from "../../utils/commonHooks";

import classes from './header.module.scss';

import menuBookIconImg from './menuBookIcon.svg';


interface HeaderProps {

}

const Header: FC<HeaderProps> = props => {
	const {} = props;
	
	const {i18n} = useTranslation();
	
	const languageOptions = useLanguageOptions();
	
	return (
		<header className={classes.header}>
			<div className={classes.left_side}>
				<img
					src={menuBookIconImg}
					alt="Menu book icon"
					width={24}
					height={24}
				/>
				<p>AI</p>
			</div>
			<div className={classes.center_side}>
			
			</div>
			<div className={classes.right_side}>
				<LanguageSelect
					options={languageOptions}
					value={i18n.language}
					onSet={lang => i18n.changeLanguage(lang)}
				/>
			</div>
		</header>
	)
}

export default Header;