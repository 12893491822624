/** @format */

import React, {FC, useCallback, useState} from 'react';
import {motion} from 'framer-motion';

import Background from '../../components/Background';

import ZeroStepForm from './ZeroStepForm/ZeroStepForm';
import AreYouStepForm from './AreYouStepForm/AreYouStepForm';
import ScanPageStepForm from './ScanPageStepForm/ScanPageStepForm';
import ActivityStepForm from './ActivityStepForm/ActivityStepForm';
import UploadDocumentForm from './ScanPageStepForm/UploadDocumentForm';
import PasteWebsiteTextForm from './ScanPageStepForm/PasteWebsiteTextForm';

import Main from '../../components/Main';
import Header from '../../components/Header';
import SuccessStep from './SuccessStep/SuccessStep';
import LoadingStep from './LoadingStep/LoadingStep';
import SomethingGoneWrong from './SomethingGoneWrong/SomethingGoneWrong';
import StepperAnimator from '../../components/StepperAnimator/StepperAnimator';
import Stepper from '../../components/Stepper';

import {useCreateStory} from './createStoryQueries';
import {useIsMobileView} from '../../utils/commonHooks';

import {ELessonActivity, ESourceType, EUserType} from './createStoryTypes';

import classes from './createStoryPage.module.scss';


const totalSteps = 3;

const CreateStoryPage: FC = () => {
    const [step, setStep] = useState<number>(0);
    const [isSelectSourceStep, setIsSelectSourceStep] = useState<boolean>(true);
    const [direction, setDirection] = useState<'forward' | 'backward'>('forward');

    const [userName, setUserName] = useState<string>('');
    const [topic, setTopic] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [userType, setUserType] = useState<EUserType>(EUserType.teacher);
    const [sourceType, setSourceType] = useState<ESourceType>(ESourceType.classroomBook);
    const [file, setFile] = useState<File | null>(null);
    // const [websiteLink, setWebsiteLink] = useState<string>('');
    const [websiteText, setWebsiteText] = useState<string>('');
    const [language, setLanguage] = useState('he');

    const [activities, setActivities] = useState<Record<ELessonActivity, boolean>>({
        [ELessonActivity.coloringGame]: true,
        [ELessonActivity.labelingGame]: true,
        [ELessonActivity.sequenceGame]: true,
        [ELessonActivity.fillInTheBlank]: true,
        [ELessonActivity.matchingGame]: true,
        [ELessonActivity.shortAnswerQuestions]: true,
        [ELessonActivity.tableCompletion]: true,
        [ELessonActivity.trueFalse]: true,
        [ELessonActivity.multipleChoice]: true,
        [ELessonActivity.shortVideo]: false,
    });

    const {
        createStory,
        isStoryCreating,
        storyCreationError,
        isStoryCreatingError,
    } = useCreateStory();

    const handleCreateStory = () => {
        setStep(4);
        createStory({
            email,
            file,
            topic,
            name: userName,
            userType,
            language,
            websiteText,
            activities,
            sourceType,
        })
    }

    const handleBackToSelectSourceType = useCallback(() => {
        setDirection('backward')
        setIsSelectSourceStep(true);
    }, [setIsSelectSourceStep, setDirection])

    const handleGoToUpload = useCallback(() => {
        setDirection('forward');
        setIsSelectSourceStep(false);
    }, [setIsSelectSourceStep, setDirection])

    const handleNext = useCallback(() => {
        setDirection('forward');
        setStep(step => step + 1)
    }, [setStep, setDirection])

    const handleBack = useCallback(() => {
        setDirection('backward');
        setStep(step => step - 1);
    }, [setStep, setDirection]);

    const handleSelectStep = useCallback((step: number) => {
        setDirection('backward');
        setStep(step);
    }, [setStep, setDirection])

    let content: React.ReactElement | null = null;

    switch (step) {
        case 0: {
            content = (
                <ZeroStepForm
                    key="zero-step-form"

                    userName={userName}
                    setUserName={setUserName}

                    topic={topic}
                    setTopic={setTopic}

                    email={email}
                    setEmail={setEmail}

                    onSubmit={handleNext}
                />
            );
            break;
        }
        case 1: {
            content = (
                <AreYouStepForm
                    key="are-you-step-form"

                    userType={userType}
                    setUserType={setUserType}

                    onBack={handleBack}
                    onSubmit={handleNext}
                />
            )
            break;
        }
        case 2: {
            if (isSelectSourceStep) {
                content = (
                    <ScanPageStepForm
                        key="scan-step-form"

                        documentType={sourceType}
                        setDocumentType={setSourceType}

                        onBack={handleBack}
                        onSubmit={handleGoToUpload}
                    />
                );
            } else if (sourceType === ESourceType.websiteText) {
                content = (
                    <PasteWebsiteTextForm
                        websiteText={websiteText}
                        setWebsiteText={setWebsiteText}
                        
                        language={language}
                        setLanguage={setLanguage}
                        
                        onSubmit={handleNext}
                        onBack={handleBackToSelectSourceType}
                    />
                );
            } else {
                content = (
                    <UploadDocumentForm
                        file={file}
                        setFile={setFile}

                        language={language}
                        setLanguage={setLanguage}

                        onSubmit={handleNext}
                        onBack={handleBackToSelectSourceType}
                    />
                )
            }

            break;
        }
        case 3: {
            content = (
                <ActivityStepForm
                    key="sessions-step-form"
                    
                    activities={activities}
                    setActivities={setActivities}
                    
                    onBack={handleBack}
                    onSubmit={handleCreateStory}
                />
            );
            break;
        }
        case 4: {

            if (isStoryCreating) {
                content = (
                    <LoadingStep key="request-step"/>
                );
                break;
            }

            if (isStoryCreatingError) {
                content = (
                    <SomethingGoneWrong
                        error={storyCreationError}

                        onBack={handleBack}
                        onRetry={handleCreateStory}
                    />
                )
                break;
            }
            
            content = (
                <SuccessStep
                    key="request-step"
                    totalSteps={totalSteps}
                    step={step}
                    setStep={setStep}
                    
                    i18nIsDynamicList
                />
            )
        }
    }
    
    const isMobileView = useIsMobileView();
    
    return (
        <Main className={classes.create_story_page}>
            <Header/>
            <Background/>
            <motion.div
                className={classes.container}
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 20}}
            >
                {!isMobileView && (
                    <div
                        className={classes.stepper_container}
                        data-is-show={step !== 0 && step !== 4}
                    >
                        <Stepper
                            max={totalSteps}
                            value={step}
                            onSet={handleSelectStep}
                        />
                    </div>
                )}
                <StepperAnimator direction={direction}>
                    {content}
                </StepperAnimator>
            </motion.div>
        </Main>
    )
}

export default CreateStoryPage;