/** @format */

import React, {FC, useCallback} from 'react';

import Checkbox from '../../../components/Checkbox/Checkbox';

import {TImageMaterialStructure} from '../learningMaterialsTypes';

import classes from './editingBlock.module.scss'

import editIconImg from './editIcon.svg';
import TextArea from "./TextArea";
import {useTranslation} from "react-i18next";


interface ImageEditingBlockProps {
	title?: string,
	subTitle?: string,
	
	image: TImageMaterialStructure
	setImage: React.Dispatch<React.SetStateAction<TImageMaterialStructure>>;
}

const ImageEditingBlock: FC<ImageEditingBlockProps> = props => {
	const {
		title,
		subTitle,
		image,
		setImage,
	} = props;
	
	const {t} = useTranslation();
	
	const handleChangeRegenerate = useCallback((regenerate: boolean) => {
		setImage(text => ({
			...text,
			regenerate,
		}))
	}, [setImage]);

	const handleChangeComment = useCallback((comment: string) => {
		setImage(text => ({
			...text,
			comment,
		}))
	}, [setImage]);

	return (
		<div className={classes.block}>
			{(title !== undefined || subTitle) && (
				<div className={classes.block_header}>
					{title && (<h4>{title}</h4>)}
					{subTitle && (<h5>{subTitle}</h5>)}
				</div>
			)}
			
			<div className={classes.block_separator}>
				<img
					className={classes.block_content_image}
					src={image.url}
					alt={image.comment}
				/>
				<div className={classes.block_actions}>
					
					<div className={classes.action_container}>
						<label className={classes.label}>
							<Checkbox
								checked={image.regenerate}
								onSet={handleChangeRegenerate}
							/>
							<span>{t('common.regenerate')}</span>
						</label>
					</div>
					
					{image.regenerate && (
						<TextArea
							value={image.comment}
							onSet={handleChangeComment}
							autoFocus
						/>
					)}
					
				</div>
			</div>
			
		</div>
	)
}

export default ImageEditingBlock;