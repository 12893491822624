/** @format */

import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";

import Button from "../../../components/Button";
import Select from "../../../components/Select/Select";

import {useLanguageOptions} from "../../../utils/commonHooks";

import {TCreateStoryPageStepProps} from "../createStoryPageTypes";

import classes from './scanPageStepForm.module.scss';

interface PasteWebsiteTextFormProps extends TCreateStoryPageStepProps {
	websiteText: string;
	setWebsiteText: (websiteText: string) => void,
	
	language: string,
	setLanguage: (language: string) => void,
}

const PasteWebsiteTextForm: FC<PasteWebsiteTextFormProps> = props => {
	const {
		websiteText,
		setWebsiteText,
		
		onBack,
		onSubmit,
		
		language,
		setLanguage,
	} = props;
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	const {t} = useTranslation();
	
	const languageOptions = useLanguageOptions();
	
	return (
		<form
			className={classes.paste_website_text_form}
			action="#"
			onSubmit={handleSubmit}
		>
			<h1 className={classes.title}>{t('creation.paste_website_test_step.title')}</h1>
			
			<div className={classes.center}>
				<div className={classes.select_container}>
					<h5 className={classes.select_label}>
						{t('creation.upload_document_step.select_language')}
					</h5>
					<Select
						options={languageOptions}
						value={language}
						onSet={setLanguage}
					/>
				</div>
			</div>
			<textarea
				value={websiteText}
				onChange={e => setWebsiteText(e.currentTarget.value)}
				className={classes.textarea}
				placeholder={t('creation.paste_website_test_step.placeholder')}
			/>
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default PasteWebsiteTextForm;