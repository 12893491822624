/** @format */

import React, {FC} from 'react';
import Stepper from "../../../components/Stepper";

import {TCreateStoryPageStepProps} from "../createStoryPageTypes";

import Button from "../../../components/Button";

import classes from './somethingGoneWrong.module.scss';
import {useTranslation} from "react-i18next";

interface SomethingGoneWrongProps extends Omit<TCreateStoryPageStepProps, 'onSubmit'> {
	error: any,
	onRetry?: () => void,
}

const SomethingGoneWrong: FC<SomethingGoneWrongProps> = props => {
	const {
		error,
		onBack,
		onRetry,
	} = props;
	
	const {t} = useTranslation();
	
	return (
		<div className={classes.form}>
			<h1 className={classes.title}>{t('common.something_went_wrong')}</h1>
			<p className={classes.description}>
				{String(error?.message ?? error?.status)}
			</p>
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>Back</Button>
				<Button onClick={onRetry}>Retry</Button>
			</div>
		</div>
	)
}

export default SomethingGoneWrong;