/** @format */

import React, {ChangeEvent, FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../../components/Button';
import CheckboxBlock from '../../../components/CheckboxBlock';

import {TCreateStoryPageStepProps} from '../createStoryPageTypes';
import {ELessonActivity} from '../createStoryTypes';

import classes from './activityStepForm.module.scss'


interface SessionsStepFormProps extends TCreateStoryPageStepProps {
	activities: Record<ELessonActivity, boolean>;
	setActivities: (pages: Record<ELessonActivity, boolean>) => void;
}

const ActivityStepForm: FC<SessionsStepFormProps> = props => {
	const {
		activities,
		setActivities,
		
		onBack,
		onSubmit,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	const handleTogglePage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setActivities({
			...activities,
			[event.currentTarget.value]: event.currentTarget.checked,
		});
	}, [setActivities, activities]);
	
	return (
		<form
			action="#"
			className={classes.activity_form}
			onSubmit={handleSubmit}
		>
			<h3 className={classes.description}>
				{t('creation.activity_step.title')}
			</h3>
			<div className={classes.activity_grid}>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.tableCompletion}
					className={classes.activity_checkbox_block}
					checked={activities[ELessonActivity.tableCompletion]}
					onChange={handleTogglePage}
				>
					<p className={classes.activity_checkbox_block_text}>
						{t('creation.activity_step.table_completion')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.trueFalse}
					className={classes.activity_checkbox_block}
					checked={activities[ELessonActivity.trueFalse]}
					onChange={handleTogglePage}
				>
					<p className={classes.activity_checkbox_block_text}>
						{t('creation.activity_step.true_false')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.sequenceGame}
					className={classes.activity_checkbox_block}
					checked={activities[ELessonActivity.sequenceGame]}
					onChange={handleTogglePage}
				>
					<p className={classes.activity_checkbox_block_text}>
						{t('creation.activity_step.sequence_game')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.labelingGame}
					className={classes.activity_checkbox_block}
					checked={activities[ELessonActivity.labelingGame]}
					onChange={handleTogglePage}
				>
					<p className={classes.activity_checkbox_block_text}>
						{t('creation.activity_step.labeling_game')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.fillInTheBlank}
					className={classes.activity_checkbox_block}
					checked={activities[ELessonActivity.fillInTheBlank]}
					onChange={handleTogglePage}
				>
					<p className={classes.activity_checkbox_block_text}>
						{t('creation.activity_step.fill_in_game')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.shortVideo}
					className={classes.activity_checkbox_block}
					checked={activities[ELessonActivity.shortVideo]}
					// onChange={handleTogglePage}
					disabled
				>
					<p className={classes.activity_checkbox_block_text}>
						{t('creation.activity_step.short_video')}
					</p>
				</CheckboxBlock>
			</div>
			
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default ActivityStepForm;