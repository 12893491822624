/** @format */

import React, {FC} from 'react';

import classes from './loader.module.scss'


interface LoaderProps {
	size?: number,
	width?: number,
	inverted?: boolean,
}

const Loader: FC<LoaderProps> = props => {
	const {size = 64, width = 2, inverted = false} = props;
	
	return (
		<div
			className={classes.loader}
			data-inverted={inverted}
			style={{
				width: size,
				height: size,
				borderWidth: width,
			}}
		/>
	)
}

export default Loader;