/** @format */

import React, {ChangeEvent, FC, useCallback} from 'react';
import CheckboxBlock from "../../../components/CheckboxBlock";

import classes from './scanPageStepForm.module.scss'

import classroomBookImg from "./classroomBook.svg";
import classroomBookletImg from "./classroomBooklet.svg";
import websiteLinkImg from "./websiteLink.svg";
import Button from "../../../components/Button";
import {TCreateStoryPageStepProps} from "../createStoryPageTypes";
import {ESourceType} from "../createStoryTypes";
import {useTranslation} from "react-i18next";

interface ScanPageStepFormProps extends TCreateStoryPageStepProps {
	documentType: ESourceType,
	setDocumentType: (documentType: ESourceType) => void,
}

const ScanPageStepForm: FC<ScanPageStepFormProps> = props => {
	const {
		documentType,
		setDocumentType,
		
		onBack,
		onSubmit,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	const handleCustomerTypeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.checked) {
			setDocumentType(event.currentTarget.value as ESourceType);
		}
	}, [setDocumentType]);
	
	return (
		<form action="#" className={classes.scan_page_form} onSubmit={handleSubmit}>
			<h1 className={classes.title}>
				{t('creation.scan_page_step.title')}
			</h1>
			<h3 className={classes.description}>
				{t('creation.scan_page_step.description')}
			</h3>
			<div className={classes.checkbox_blocks}>
				<CheckboxBlock
					radio
					name="document_type"
					value={ESourceType.classroomBook}
					checked={documentType === ESourceType.classroomBook}
					onChange={handleCustomerTypeChange}
				>
					<div className={classes.checkbox_block_image_with_text}>
						<img
							src={classroomBookImg}
							alt="Classroom Book"
							width={196}
							height={146}
						/>
						<p>{t('creation.scan_page_step.classroom_book')}</p>
					</div>
				</CheckboxBlock>
				<CheckboxBlock
					radio
					name="document_type"
					value={ESourceType.classroomBooklet}
					checked={documentType === ESourceType.classroomBooklet}
					onChange={handleCustomerTypeChange}
				>
					<div className={classes.checkbox_block_image_with_text}>
						<img
							src={classroomBookletImg}
							alt="Classroom Booklet"
							width={150}
							height={146}
						/>
						<p>{t('creation.scan_page_step.classroom_booklet')}</p>
					</div>
				</CheckboxBlock>
				<CheckboxBlock
					radio
					name="document_type"
					value={ESourceType.websiteText}
					checked={documentType === ESourceType.websiteText}
					onChange={handleCustomerTypeChange}
				>
					<div className={classes.checkbox_block_image_with_text}>
						<img
							src={websiteLinkImg}
							alt="Classroom Booklet"
							width={196}
							height={146}
						/>
						<p>{t('creation.scan_page_step.paste_website_text')}</p>
					</div>
				</CheckboxBlock>
			</div>
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default ScanPageStepForm;